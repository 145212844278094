<template>
  <div>
    <common-header :title="title"></common-header>
    <div class="warp">
      <div class="search-warp">
        <div class="sarch-big-box" :style="`top:${isWeiXin() ? '0px' : '40px'}`">
          <div class="search-box">
            <input class="search-input" type="text" v-focus="autoInput" placeholder="搜索商品" @input="search"
              v-model="searchVal" @blur="blurSearch">
            <i class="icon-search-fdj"></i>
            <img class="clear-input" @click="clearInput" v-if="searchVal != ''" src="@/assets/images/img/clear-input.png"
              alt="">
          </div>
          <div class="search-btn jb-r-l" @click="btnSearch">搜索</div>
        </div>
      </div>

      <ul class="search-res" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading"
        infinite-scroll-immediate-check="false" infinite-scroll-distance="10" v-if="searchVal != '' || title != ''">
        <li class="item-li" v-for="(item, index) in searchDa.list" :key="index"
          @click="$router.push(`/shopping/details?id=${item.id}`)">
          <div class="item">
            <div class="good-imgbox pre">
              <img class="goods-img" :key="getGoodsImg(item)" alt="" v-lazy="getGoodsImg(item)">
              <span class="quehuo"
                v-if="item.specList && item.specList.length > 0 && item.specList[0].inventory === 0">缺货</span>
            </div>
            <div class="content ">
              <div class="name">{{ item.name }}</div>
              <div class="ms" v-if="!item.specList[0].seckill && item.specList[0].minLimit == 1 && !item.tags">
                {{ item.description }}</div>
              <div class="tags-box" v-else>
                <div class="tag m-kill">月销{{ item.virtualMonthlySales + item.monthlySales }}{{ item.unitName != '' ?
                  item.unitName : '' }}</div>
                <div class="tag m-kill" v-if="item.specList[0].seckill">秒杀</div>
                <template v-if="item.tags">
                  <div class="tag tags-item" v-for="(tagItem, tagIndex) in (item.tags).split(',')" :key="tagIndex">
                    {{ tagItem }}
                  </div>
                </template>
                <div class="tag buy-from" v-if="item.specList[0].minLimit > 1">
                  {{ item.specList[0].minLimit }}{{ item.specList[0].unitName != '' ? item.specList[0].unitName : (item.unitName) }}起购
                </div>
              </div>
              <div class="price-box">
                <div class="is-certified"
                  v-if="(Number(item.specList[0].price)) < 0 && Number(item.specList[0].unitPrice) < 0">{{ isCertified() }}
                </div>
                <div class="price" v-else>
                  <div class="price-details" v-if="!item.specsShow">
                    <span class="unit-tips"
                      v-if="item.specList[0].unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">约{{ item.specList[0].unitQuantity }}{{ item.specList[0].minUnitName }}</span>
                    <span
                      :class="item.specList[0].unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 ? 'hui' : ''">
                      <span class="unit">￥</span>{{ (Number(item.specList[0].price) + item.specList[0].deposit) }}<span
                        class="unit">{{ item.specList[0].unitName != '' ? '/' + item.specList[0].unitName : (item.unitName != '' ?
                          `/${item.unitName}` : '') }}</span>
                    </span>
                    <div class="deposit" v-if="item.specList[0].deposit > 0">(含押金{{ item.specList[0].deposit }}元)</div>
                  </div>
                  <div class="no-unitName"
                    v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 && !item.specsShow">
                    <span class="unit">￥</span>{{ item.specList[0].unitPrice }}<span
                      class="unit">{{ item.specList[0].minUnitName != '' ? `/${item.specList[0].minUnitName}` : '' }}</span>
                  </div>
                </div>


                <div class="specs-btn-box jb-r-l" v-if="item.specList && item.specList.length > 1"
                  @click.stop="changeSpecs(index)">{{ item.specsShow ? "收起" : "规格" }}</div>
                <div class="icon-car-btn" v-else
                  @click.stop=" SelectProducts(item.id, item.specList[0].id, item.inventory)"></div>

              </div>
            </div>
          </div>
          <!-- 规格选项 start -->
          <div class="specs-box" :class="item.specsShow ? 'active' : ''"
            v-if="item.specList && item.specList.length > 1 && item.specsShow">
            <div class="specs-item" v-for="(item2, index2) in item.specList" :key="index2">
              {{ item2.name }} <span class="deposit"
                v-if="item2.deposit > 0 && item2.price > 0">(含押金{{ item2.deposit }}元)</span>
              <div class="is-certified" v-if="item2.price < 0">{{ isCertified() }}</div>
              <span class="present-price" v-else>
                <span class="unit">￥</span>
                <span class="moeny">{{ item2.price + item2.deposit }}</span>
                <span class="unit">{{ item2.unitName != '' ? '/' + item2.unitName : (item.unitName != "" ? "/" + item.unitName :
                  "") }}</span>
              </span>
              <div class="specs-btn jb-r-l" @click.stop="SelectProducts(item.id, item2.id)">+</div>
            </div>
          </div>
          <!-- 规格选项 end -->
        </li>
      </ul>

      <div class="no-data" v-if="nodata && searchVal != ''">
        <no-data></no-data>
        <router-link v-if="tagsCode == ''" :to="`/shopping/newproduct?searchkey=${searchVal}`"
          class="new-demand">提交新品需求</router-link>
      </div>

      <!-- 历史搜索 start -->

      <div class="search-his" v-if="historySearch.length > 0 && searchVal == '' && !nodata && tagsCode == ''">
        <div class="title">
          历史搜索
          <div class="btn" @click="clearHistory">清空</div>
        </div>
        <ul class="his-list">
          <li class="item" v-for="(item, index) in historySearch" v-if="index < 15" :key="index"
            @click="historyClick(item)">{{ item }}</li>
        </ul>
      </div>
      <!-- 历史搜索 end -->

      <!-- 热门搜索 start -->
      <div class="search-his" v-if="hotSearch.length > 0 && searchVal == '' && !nodata && tagsCode == ''">
        <div class="title">
          推荐搜索
        </div>
        <ul class="his-list">
          <li class="item" v-for="(item, index) in hotSearch" v-if="index < 15" :key="index" @click="historyClick(item)">
            {{ item }}</li>
        </ul>
      </div>
      <!-- 热门搜索 end -->
      <router-link to="/shopping/car" class="gwc-box">
        <img src="@/assets/images/img/gwc-xf.png" alt="">
        <mt-badge v-if="goodsNum > 0" class="badge" color="#eb3030" size="small">{{ goodsNum }}</mt-badge>
      </router-link>
    </div>

    <!-- <div class="jz" v-if="loadingJZ"></div> -->
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { requestImgUrl, getGoodsImg, CountDown, isWeiXin } from "@/utils/common";
import { isCertified } from "@/utils/utils";
export default {
  data() {
    this.getGoodsImg = v => getGoodsImg(v);
    this.isWeiXin = () => isWeiXin();
    this.isCertified = () => isCertified();
    return {
      searchVal: '',
      searchDa: [],
      nodata: false,
      autoInput: true,
      loading: true,
      nomore: false,
      uid: localStorage.getItem('uid'),
      name: "",
      tagsCode: "",
      // 标题
      title: "",
      pageNum: 1,
      // 历史搜索
      historySearch: [],
      hotSearch: [],
      lastTime: 0,
    }
  },
  activated() {
    this.definit();
  },
  created() {
    this.definit();
  },
  directives: {
    focus: {
      inserted: function (el, { value }) {
        if (value) {
          el.focus()
        }
      }
    }
  },
  deactivated() {
    if (this.$route.path == '/') {
      this.searchDa = [];
      this.pageNum = 1;
      this.searchVal = '';
    }
  },
  methods: {
    definit() {
        let name = this.$route.query.name;
        let title = this.$route.query.title;
        let tagsCode = this.$route.query.tagsCode;
        this.name = name;
        this.tagsCode = tagsCode ? tagsCode : '';
        if (title) {
          this.title = title;
        }
        if (name) {
          this.searchVal = name;
        }
        if (name || tagsCode) {
          this.autoInput = false;
          this.init({
            name: name,
            tagsCode: tagsCode,
          });
        }



      if (localStorage.getItem('historySearch')) {
        this.historySearch = JSON.parse(localStorage.getItem('historySearch'))
      }
      // 热门搜索
      this.getHotSearch().then((res) => {
        this.hotSearch = res.data;
      })
    },
    // 显示规格列表
    changeSpecs(index) {
      let commodity = this.searchDa.list[index];
      commodity.specsShow = !commodity.specsShow;
      this.$set(this.searchDa.list, index, commodity);
    },
    // 清空历史记录
    clearHistory() {
      localStorage.removeItem('historySearch');
      this.historySearch = [];
    },
    // 历史点击
    historyClick(item) {
      this.searchVal = item;
      this.init({
        tagsCode: this.tagsCode,
        name: item,
      })
    },
    // 失去焦点
    blurSearch() {
      if (this.searchVal != '') {
        if (this.historySearch.indexOf(this.searchVal) < 0) {
          this.historySearch.unshift(this.searchVal);
          // 记录超过10个参数
          if (this.historySearch.length > 15) {
            this.historySearch.pop();
          }
          localStorage.setItem('historySearch', JSON.stringify(this.historySearch));
        }
      }
    },
    // 清空
    clearInput() {
      this.searchVal = "";
      this.nodata = false;
      this.searchDa = [];
      this.nomore = false;
      // this.$router.go(-1);
    },
    search() {
      // if (this.searchVal === '') {
      //   return;
      // }

      this.init({
        name: this.searchVal,
        tagsCode: this.tagsCode,
      })

    },
    // 搜索接口
    init(obj) {

      if (obj.name == '' && this.tagsCode == '') {
        return false;
      }
      // this.$Indicator.open({
      //   text: '加载中...',
      //   spinnerType: 'snake',
      // });
      this.GetCommodityList({
        searchKey: obj.name,
        tagsCode: obj.tagsCode,
        pageNum: obj.pageNum ? obj.pageNum : 1,
        pageSize: 30,
      }).then((res) => {
        // this.$Indicator.close();
        this.loading = false;
        let searchDa = this.searchDa;
        if (obj.pageNum) {
          searchDa.list = searchDa.list.concat(res.data.list);
        } else {
          this.searchDa = res.data;
          this.nodata = res.data.total > 0 ? false : true;
        }
      })
    },
    loadMore() {
      this.loading = true;
      let pageNum = this.pageNum + 1;
      this.pageNum = pageNum;
      if (pageNum > this.searchDa.pages) {
        this.nomore = true;
      } else {
        this.init({
          tagsCode: this.tagsCode,
          name: this.searchVal,
          pageNum: pageNum,
        });
      }
    },
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if (inventory <= 0) {
        this.$toast("卖光啦！");
        return false;
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid,
      }).then((res) => {
        if (res.status == 200) {
          this.$toast("加入购物车成功")
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then((res) => {
            this.goodsNumMuta(res.data.quantity)
          })
        }
      })
    },
    btnSearch() {
      this.init({
        name: this.searchVal,
        tagsCode: this.tagsCode,
      });
    },
    ...mapMutations(["goodsNumMuta"]),
    ...mapActions('shopping', [
      'GetCommodityList',
      'getCommodityCount',
      'AddCommodityCart',
      'getHotSearch',
    ])
  },
  computed: {
    ...mapState(["orgInfo", "goodsNum"]),
  },
}
</script>
<style lang='less' scoped>
.jz {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffff;
  z-index: 9999;
}

.price-details {
  display: flex;
  align-items: center;
}

.deposit {
  color: #ccc;
  font-size: .24rem;
  margin-left: .15rem;
}

.no-data {
  text-align: center;
}

.new-demand {
  background: #ff7141;
  color: #fff;
  padding: .15rem .15rem;
  margin-top: .1rem;
  display: inline-block;
  border-radius: .1rem;
  font-size: .28rem;
}

.unit-tips {
  font-size: .24rem;
  background: #ffefea;
  padding: .05rem .1rem;
  border-radius: .1rem;
  margin-right: .15rem;
}

.tags-box {
  max-width: 5rem;

  &::-webkit-scrollbar {
    display: none
  }
}

.clear-input {
  position: absolute;
  top: 50%;
  right: .2rem;
  margin-top: -.2rem;
  width: .4rem;
  height: .4rem;
}

.gwc-box {
  position: fixed;
  bottom: 6%;
  left: .4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #ff7141;
  display: flex;
  align-items: center;
  justify-content: center;

  .badge {
    position: absolute;
    top: -0.05rem;
    right: 0;
    font-size: 0.24rem;
    transform: translateX(40%);
  }

  img {
    width: 55%;
  }
}

.price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .15rem;

  .price {
    display: flex;
    color: #ff7241;
    font-size: 0.32rem;
    font-weight: bold;
    flex-direction: column;

    .hui {
      color: #8a8a8a;
      font-weight: normal;
    }

    .no-unitName {
      margin-top: .15rem;
      display: flex;
      align-items: center;
    }

    .unit {
      font-size: 0.24rem;
    }
  }
}

.search-res {
  .content {
    flex: 1;

    .name {
      font-size: 0.3rem;
      color: #262626;
      font-weight: bold;
    }

    .ms {
      font-size: 0.26rem;
      color: #bfbfbf;
      margin-top: 0.1rem;
      margin-bottom: 0.25rem;
    }
  }

  .item-li {
    background: #fff;
    border-radius: 0.1rem;
    margin-bottom: 0.2rem;
    padding: 0.3rem 0.15rem;

    .quehuo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 5;
      background-color: rgba(167, 167, 167, 0.7);
      color: #fff;
      width: 1rem;
      height: 1rem;
      font-size: 0.28rem;
      text-align: center;
      line-height: 1rem;
      display: block;
      border-radius: 1rem;
    }
  }

  .item {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .goods-img {
    position: relative;
    width: 1.52rem;
    height: 1.52rem;
    border-radius: 0.05rem;
    margin-right: 0.2rem;
  }
}

.his-list {
  margin-top: .4rem;
  display: flex;
  flex-wrap: wrap;

  .item {
    margin-right: .2rem;
    font-size: .26rem;
    color: #595959;
    background: #FFF;
    padding: .12rem .32rem;
    border-radius: .25rem;
    margin-bottom: .2rem;
  }
}

.search-his {
  margin-top: .5rem;

  .title {
    font-size: .3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      font-size: 0.24rem;
      color: #595959;
    }
  }
}

.search-warp {
  height: 1.08rem;
}

.sarch-big-box {
  display: flex;
  padding: .2rem;
  position: fixed;
  height: 1.08rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #f7f7f7;

  .search-btn {
    color: #fff;
    min-width: 1.2rem;
    margin-left: .2rem;
    font-size: .28rem;
    text-align: center;
    line-height: .68rem;
    border-radius: 0.3rem;
  }
}

.search-box {
  width: 100%;
  height: .68rem;
  background: #fff;
  border-radius: .3rem;
  position: relative;

  .icon-search-fdj {
    position: absolute;
    top: 50%;
    left: .2rem;
    transform: translateY(-50%);
  }

  .search-input {
    width: 100%;
    height: 100%;
    font-size: .26rem;
    text-indent: .6rem;
  }
}

.specs-box {
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 5rem;
    margin-top: .26rem;
  }
}

.specs-btn-box {
  color: #fff;
  font-size: .24rem;
  padding: .1rem;
  border-radius: .1rem;
  display: flex;
}

.specs-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: .28rem;
  height: .8rem;
  padding: 0 .25rem;

  .is-certified {
    margin-top: 0rem;
    margin-left: .05rem;
    font-size: .24rem;
    line-height: 1.2;
  }

  .present-price {
    margin-left: .1rem;
    color: #ff7241;
    font-size: 0.32rem;
    font-weight: bold;
    flex-direction: column;

    .unit {
      font-size: 0.24rem;
    }
  }

  .specs-btn {
    height: .48rem;
    width: .48rem;
    border-radius: 50%;
    text-align: center;
    line-height: .48rem;
    color: #fff;
    margin-left: .2rem;
  }
}</style>